
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import axios from 'axios';
import RegenerateThumbs from '@/components/buttons/RegenerateThumbs.vue';

const wsStore = namespace('ws');

@Component({ components: { RegenerateThumbs } })
export default class WsPage extends Vue {
  orderId = '';
  menu = false;
  dateRange: string[] = [];
  email = '';
  dateRangeText = '';

  @wsStore.Action
  sendInfoAboutNewVersion!: (hard: boolean) => void;

  @wsStore.Action
  sendTestOrder!: (orderId: number) => void;

  get isApiCallValid() {
    return this.dateRange.length === 2 && this.email !== '';
  }

  notifyAboutNewVersion(hard = false): void {
    confirm(this.$t('general.areYouSure') as string) && this.sendInfoAboutNewVersion(hard);
  }

  redirect(): void {
    const url = process.env.VUE_APP_APIURL + 'queues';
    window.location.href = url;
  }

  sendOrder(): void {
    confirm(this.$t('general.areYouSure') as string) && this.sendTestOrder(+this.orderId);
  }

  saveDateRange() {
    this.dateRangeText = `${this.dateRange[0]} to ${this.dateRange[1]}`;
    this.menu = false;
  }

  async makeApiCall() {
    if (this.isApiCallValid) {
      try {
        const params = {
          periodFrom: this.dateRange[0],
          periodTo: this.dateRange[1],
          email: this.email,
        };

        const response = await axios.get(`${process.env.VUE_APP_APIURL}/scrappers/uber-marketing`, {
          params,
          headers: {
            'api-key': 'test',
          },
        });
        console.log('API call response:', response.data);
      } catch (error) {
        console.error('API call error:', error);
      }
    } else {
      alert('Please select a date range and enter an email.');
    }
  }
}
